

import React, { useState, useEffect } from "react";

import "./Myteam.scss";


import StageChart from "../Stages/Stages";
import Layout from "../../Component/Layout";



const Myprofile = () => {


  return (
    <Layout>
   <>
  
     <div className="container-fluid myprofile py-5">
     

      
  
      
      <StageChart/>
    </div>
    </>
    </Layout>

  );
};

export default Myprofile;
