import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import axios from "axios";
import { useParams } from 'react-router-dom';


const stages = [
  { id: 1, multiplier: 2, color: '#f7b7b7', students: [
      { name: 'Student 1', userId: 'U001', contact: '9876543210' },
      { name: 'Student 2', userId: 'U002', contact: '9876543211' },
    ] 
  },
  { id: 2, multiplier: 4, color: '#a8cbd9', students: [
      { name: 'Student 3', userId: 'U003', contact: '9876543212' },
      { name: 'Student 4', userId: 'U004', contact: '9876543213' },
    ] 
  },
  { id: 3, multiplier: 8, color: '#9cd58b', students: [
      { name: 'Student 5', userId: 'U005', contact: '9876543214' },
      { name: 'Student 6', userId: 'U006', contact: '9876543215' },
    ] 
  },
  { id: 4, multiplier: 16, color: '#92c5e5', students: [
      { name: 'Student 7', userId: 'U007', contact: '9876543216' },
      { name: 'Student 8', userId: 'U008', contact: '9876543217' },
    ] 
  },
  { id: 5, multiplier: 32, color: '#f6db8f', students: [
      { name: 'Student 9', userId: 'U009', contact: '9876543218' },
      { name: 'Student 10', userId: 'U010', contact: '9876543219' },
    ] 
  },
];



const StageChart = () => {
 //let userstaging = userstage()

 const {u_id} =useParams()
 console.log(u_id);
 

 
  const [selectedStage, setSelectedStage] = useState(null);
  // const [selectedStage1, setSelectedStage1] = useState(null);

  const handleStageClick = (stage) => {
    setSelectedStage(stage);
  };

  const [userstage1, setuserstage1] = useState([]);
  const [userstage2, setuserstage2] = useState([]);
  const [userstage3, setuserstage3] = useState([]);
  const [userstage4, setuserstage4] = useState([]);
  const [userstage5, setuserstage5] = useState([]);


  useEffect(() => {
   Userstage()
  }, [])
  
  const Userstage = async () => {
    const user_token = localStorage.getItem('user_token');
    const user_id = localStorage.getItem('user_id');
    console.log(user_id, 'user_iduser_id', user_token, 'user_tokenuser_token');
  
    try {
      const stageid ={
        u_id : u_id
      }
      const response = await axios.post(
        'https://lunarsenterprises.com:6001/e888/admin/stage/list',   stageid ,
        {
         
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );
  
      // Update state with the fetched data
      setuserstage1(response.data.stage1 || []);
      setuserstage2(response.data.stage2 || []);
      setuserstage3(response.data.stage3 || []);
      setuserstage4(response.data.stage4 || []);
      setuserstage5(response.data.stage5 || []);
  
    } catch (error) {
      console.log(error);
    }
  };
  

  return (
    <>
      <Container fluid className="p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4 className="text-black text-center mb-5">My Team</h4>
        <Row>
          {stages.map((stage) => (
            <Col key={stage.id} xs={12} md={6} lg={4} className="mb-4" onClick={() => handleStageClick(stage)}>
              <Card
                style={{
                  backgroundColor: stage.color,
                  cursor: 'pointer',
                  textAlign: 'center',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Card.Body>
                  <h5>500 x {stage.multiplier}</h5>
                  <p>{stage.id} Stage</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          {selectedStage ?
            selectedStage.id == 1 && (
            <Col>
              <h4 className='text-center mb-5'>Level {selectedStage.id}</h4>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Ref Id</th>
                    <th>Level</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {userstage1.map((student, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.u_name}</td>
                      <td>{student.u_referal_id}</td>
                      <td>{selectedStage.id}</td>
                      <td>{student.u_mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ): null}
        
          {selectedStage ? selectedStage.id == 2 && (
            <Col>
              <h4 className='text-center mb-5'>Level {selectedStage.id}</h4>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Ref Id</th>
                    <th>Level</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {userstage2.map((student, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.u_name}</td>
                      <td>{student.u_referal_id}</td>
                      <td>{selectedStage.id}</td>
                      <td>{student.u_mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ): null}
          {selectedStage ? selectedStage.id == 3 && (
            <Col>
              <h4 className='text-center mb-5'>Level {selectedStage.id}</h4>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Ref Id</th>
                    <th>Level</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {userstage3.map((student, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.u_name}</td>
                      <td>{student.u_referal_id}</td>
                      <td>{selectedStage.id}</td>
                      <td>{student.u_mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ): null}
          {selectedStage ? selectedStage.id == 4 && (
            <Col>
              <h4 className='text-center mb-5'>Level {selectedStage.id}</h4>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Ref Id</th>
                    <th>Level</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {userstage4.map((student, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.u_name}</td>
                      <td>{student.u_referal_id}</td>
                      <td>{selectedStage.id}</td>
                      <td>{student.u_mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ): null}
          {selectedStage ? selectedStage.id == 5 && (
            <Col>
              <h4 className='text-center mb-5'>Level {selectedStage.id}</h4>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Ref Id</th>
                    <th>Level</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {userstage5.map((student, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.u_name}</td>
                      <td>{student.u_referal_id}</td>
                      <td>{selectedStage.id}</td>
                      <td>{student.u_mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ):null}
        </Row>
      </Container>
    
    </>
  );
};

export default StageChart;
