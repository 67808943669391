import PropTypes from "prop-types";

const Layout = ({ children }) => {
  return <div className="cx-main-layout">{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.any // PropTypes for 'children'
};
export default Layout;
