import React, { useEffect, useState } from "react";
import "./Editmember.scss";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Component/Layout";

const Editmember = () => {
  const [userData, setUserData] = useState({
    u_name: "",
    u_gender: "",
    u_email: "",
    u_mobile: "",
    u_payment_type: "",
    u_state: "",
    u_district: "",
    u_address: "",
    u_referal_id: "",
    u_password: ""
  });

  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const { editMember } = useParams();

  useEffect(() => {
    const user_token = localStorage.getItem("user_token");

    axios
      .post(
        "https://lunarsenterprises.com:6001/e888/admin/user/view",
        { user_id: editMember },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      )
      .then((response) => {
        const user = response.data.data[0];
        setUserData(user);
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        console.error(error);
      });
  }, [user_id]);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("user_token");
    const userId = localStorage.getItem("user_id");

    let input = {
      u_id: editMember,
      u_name: userData.u_name,
      u_gender: userData.u_gender,
      u_email: userData.u_email,
      u_mobile: userData.u_mobile,
      u_payment_type: userData.u_payment_type,
      u_payment_mobile: userData.u_payment_mobile,
      u_state: userData.u_state,
      u_district: userData.u_district,
      u_address: userData.u_address,
      u_referal_id: userData.u_referal_id,
      u_password: userData.u_password
    }

    axios
      .post("https://lunarsenterprises.com:6001/e888/admin/user/update", input, {
        headers: {
          Authorization: `Bearer ${token}`,
          user_id: userId,
        },
      })
      .then((response) => {
        toast.success("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile");
      });
  };

  // if (!userData.u_name) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Layout>
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <h4 className="mb-4">My Profile</h4>

          <Form.Label htmlFor="u_name" className="mb-2">
            Name
          </Form.Label>
          <Form.Control
            type="text"
            id="u_name"
            value={userData.u_name}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_gender" className="mb-2 mt-2">
            Gender
          </Form.Label>
          <Form.Control
            type="text"
            id="u_gender"
            value={userData.u_gender}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_email" className="mb-2 mt-2">
            Email
          </Form.Label>
          <Form.Control
            type="email"
            id="u_email"
            value={userData.u_email}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_mobile" className="mb-2 mt-2">
            Contact number
          </Form.Label>
          <Form.Control
            type="text"
            id="u_mobile"
            value={userData.u_mobile}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_payment_type" className="mb-2 mt-2">
            Payment Method
          </Form.Label>
          <Form.Control
            type="text"
            id="u_payment_type"
            value={userData.u_payment_type}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_payment_mobile" className="mb-2 mt-2">
            Payment Mobile
          </Form.Label>
          <Form.Control
            type="text"
            id="u_payment_mobile"
            value={userData.u_payment_mobile}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_state" className="mb-2 mt-2">
            State
          </Form.Label>
          <Form.Control
            type="text"
            id="u_state"
            value={userData.u_state}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_district" className="mb-2 mt-2">
            District
          </Form.Label>
          <Form.Control
            type="text"
            id="u_district"
            value={userData.u_district}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_address" className="mb-2 mt-2">
            Address
          </Form.Label>
          <Form.Control
            type="text"
            id="u_address"
            value={userData.u_address}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_referal_id" className="mb-2 mt-2">
            Connect Id
          </Form.Label>
          <Form.Control
            type="text"
            id="u_referal_id"
            value={userData.u_referal_id}
            onChange={onHandleChange}
          />

          <Form.Label htmlFor="u_password" className="mb-2 mt-2">
            Change password
          </Form.Label>
          <Form.Control
            type="password"
            id="u_password"
            value={userData.u_password}
            onChange={onHandleChange}
          />



          <div className="mt-3 mb-5">
            <button className="btn btn-primary" type="submit">
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Editmember;
