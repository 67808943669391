import React, { useEffect, useState } from "react";
import './NotificationPage.css'; // Create a CSS file for styling if needed

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Component/Layout";

const NotificationPage = () => {
  const [amountNotifications, setAmountNotifications] = useState([]);
  const [levelNotifications, setLevelNotifications] = useState([]);

  useEffect(() => {
    Requestlist();
  }, []);

  const Requestlist = async () => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      if (user_token && user_id) {
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/admin/notification",{},
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id: user_id,
            },
          }
        );

        // Check if the response data exists and is an array
        if (response.data) {
          setAmountNotifications(response.data.amountnotification || []);
          setLevelNotifications(response.data.levelnotification || []);
        } else {
          setAmountNotifications([]);
          setLevelNotifications([]);
        }
      } else {
        console.log("User not authenticated.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAmountNotificationButton = async (u_id) => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");
      console.log(u_id,"u_id");
      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/admin/balance/approve",
        { u_id: u_id },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );

      const message = response.data.message;
      if (response.data.result === true) {
        toast.success(message);
        Requestlist(); // Refresh notifications after successful approval
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the request.");
    }
  };

  const handleLevelNotificationButton = async (u_id) => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/levelup/request/approve",
        { lr_id: u_id },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );

      const message = response.data.message;
      console.log(response.data);
      if (response.data.result === true) {
        toast.success(message);
        Requestlist(); // Refresh notifications after successful approval
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the request.");
    }
  };

  return (
    <Layout>
      <>
        <ToastContainer />
        <div className="notification-container">
          <h1 className="heading">Amount Notifications</h1>
          <ul className="notification-list">
            {amountNotifications.length > 0 ? (
              amountNotifications.map((notification) => (
                <li key={notification.u_id} className="notification-item">
                  <div className="notification-content">
                    <span className="message">{notification.u_message}</span>
                    <span className="date">{new Date(notification.u_date).toLocaleDateString()}</span>
                  </div>
                  {notification.u_status !=='approved' ?<button
                    className="accept-button"
                    onClick={() => handleAmountNotificationButton(notification.u_id)}
                  >
                    Take Action
                  </button> : <button
                    className="accept-button"
                  >
                    Approved 
                  </button>}
                </li>
              ))
            ) : (
              <p>No Amount Notifications Available</p>
            )}
          </ul>

          <h1 className="heading">Level Notifications</h1>
          <ul className="notification-list">
            {levelNotifications.length > 0 ? (
              levelNotifications.map((notification) => (
                <li key={notification.lr_id} className="notification-item">
                  <div className="notification-content">
                    <span className="username">{notification.u_name}</span>
                    <span className="userid">(Ref Id {notification.u_referal_id})</span>
                    <span className="message">{" sent a request to level up"}</span>
                    <span className="date">{new Date(notification.lr_date).toLocaleDateString()}</span>
                  </div>
                  <button
                    className="accept-button"
                    onClick={() => handleLevelNotificationButton(notification.lr_id)}
                  >
                    Accept
                  </button>
                </li>
              ))
            ) : (
              <p>No Level Notifications Available</p>
            )}
          </ul>
        </div>
      </>
    </Layout>
  );
};

export default NotificationPage;
