import React, { useState } from 'react';
import '../TransferId/TransferId.css';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from '../../Component/Layout';
import { Form, Button } from 'react-bootstrap';

const ApproveAll = () => {
    const [userConnectId, setUserConnectId] = useState('');
    //   const [masterConnectId, setMasterConnectId] = useState('');

    const handleTransferId = async () => {
        const user_token = localStorage.getItem("user_token");
        const user_id = localStorage.getItem("user_id");

        try {
            const response = await axios.post(
                "https://lunarsenterprises.com:6001/e888/admin/user/approveall",
                {
                    referal_id: userConnectId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user_token}`,
                        user_id: user_id,
                    },
                }
            );

            if (response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("There was an error transferring the ID:", error);
            toast.error("An error occurred. Please try again later.");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleTransferId();
    };

    return (
        <Layout>
            <>
                <ToastContainer />
                <div className='container profile-main mt-5'>
                    <h1 className='text-center'>Connect ID</h1>
                    <div className="profile-container">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label htmlFor="user_connect_id" className="mt-3">
                                    Connect ID
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id="user_connect_id"
                                    name="user_connect_id"
                                    placeholder="Enter connect ID"
                                    value={userConnectId}
                                    onChange={(e) => setUserConnectId(e.target.value)}
                                    required
                                    className="custom-placeholder"
                                />
                            </Form.Group>

                            <Button className="mt-4" type="submit">
                                Approve All
                            </Button>
                        </Form>
                    </div>
                </div>
            </>
        </Layout>
    );
};

export default ApproveAll;
