import { useState } from "react";
import Layout from "../Component/Layout";
import "../css/Addproduct.scss";

const AddProduct = () => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [imageHandler, setImageHandler] = useState('')

  const handleChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setImageHandler(URL.createObjectURL(file));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="text-center">
          <h3>Add Products</h3>
        </div>
        <form className="editor" onSubmit={handleSubmit}>
          <div className="image-holders">
            {imageHandler && <img src={imageHandler} alt="Product Preview" />}
          </div>
          <div className="edit-inputs">
            <div className="inp">
              <label htmlFor="image">Add Image</label>
              <input type="file" id="image" onChange={handleChange} />
            </div>

            <div className="inp">
              <label htmlFor="name">Product Name</label>
              <input
                type="text"
                id="name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            <div className="inp">
              <label htmlFor="number">Price</label>
              <input
                type="number"
                id="number"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
              />
            </div>
            <button type="submit">Add Product</button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddProduct;
